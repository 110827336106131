import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import StandingDesk from "../images/developper-desk.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Agence de développements Magento à Toulouse"
        description="Mokadev est une agence de développements Magento basée à Toulouse. Nous vous accompagnons dans vos développements e-commerce sous Magento."
        author="@mokadev"
      />

    <section className="bg-grey-lightest">
        <div className="container m-auto flex flex-col md:flex-row max-w-6xl mx-auto py-8">
            <div className="flex flex-col w-full lg:w-1/2 justify-center items-start px-4">
                <h1 className="text-2xl font-bold inline-block my-8">Merci pour votre message.</h1>
                <p className="mb-4">Une réponse vous sera apportée dans les plus brefs délais.</p>
                <a href="/" className="block bg-red-light hover:bg-white hover:text-red-light border border-red-light font-bold px-4 py-2 rounded text-white">
                  Retour
                </a>
            </div>
            <div className="w-full lg:w-1/2 lg:py-6"><img src={StandingDesk} alt="bureau debout" className="w-full"/></div>
        </div>
    </section>
    </Layout>
  );
}

export default IndexPage;
